<template>
    <div>
        <div>
            <div class="van-cell van-field" :class="isDisable ? 'van-field--disabled' : ''">
                <div class="van-cell__title van-field__label">
                    <div>
                        <span class="title">{{ formItem.label }}</span
                        ><span style="color: red" v-show="formItem.rules">*</span>
                    </div>
                    <div v-html="formItem.tips"></div>
                </div>
                <div class="van-cell__value van-field__value">
                    <div class="van-field__body" @click="initShowPicker" style="width: 90%;display: flex; flex-wrap: wrap">
                        <template v-for="(item, index) in selectedItems">
                            <div :key="index" class="el-tag el-tag--info">
                                <div class="el-select__tags-text">{{ item[formItem.txtName] }}</div>
                                <div @click.stop="deleteSelect(item)">
                                    <i-icon color="#13161B" name="iconguanbi" :size="11"/>
                                </div>
                            </div>
                        </template>
                        <template v-if="selectedItems.length == 0">
                            <div style="color: #bfbfbf">{{ formItem.placeholder }}</div>
                        </template>
                    </div>
                </div>
                <span id="multipleHiddenInput">
                    <i-field ref="inputHidden" :type="inputType" rows="1" autosize v-model="modelVal" @input="inputChange" :name="formItem.controlName" :maxlength="maxlength" :placeholder="formItem.placeholder" :rules="formItem.rules" show-word-limit>
                        <template #label> </template>
                    </i-field>
                </span>
            </div>
        </div>
        <template v-if="showPicker">
            <i-popup v-model="showPicker" position="bottom" get-container="#app" :style="{ height: formItem.postSearch ? '100%' : '50%' }">
                <i-search v-model="keywords" v-if="formItem.showSearch" @clear="onSearch" show-action
                          :placeholder="formItem.searchPlaceholder || '请输入搜索关键词'">
                    <template #action>
                        <div @click="onSearch">搜索</div>
                    </template>
                </i-search>
                <div class="tb_select_box" :style="{ height: formItem.showSearch ? 'calc(100% - 54px)' : '100%' }">
                    <div class="select_top">
                        <div class="select_cancel picker_select_btn" @click="searchClear()" type="cancel">取消</div>
                        <div class="select_title">请选择</div>
                        <div class="select_confirm picker_select_btn" @click="showPicker = false" type="confirm">确定</div>
                    </div>
                    <div v-if="currentColumns && currentColumns.length" class="select_end" @scroll="scrollColumns" :style="{ maxHeight: formItem.showSearch ? '100%' : '60vh' }">
                        <template v-for="(item, index) in currentColumns">
                            <div :key="index" @click="addSelectItem(item)" class="select_item picker_select_btn" :style="formItem.postSearch ? { padding: '10px', boxSizing: 'border-box', height: 'auto' } : {}">
                                <component v-if="formItem.itemTemplate" :is="$evalTemplate('Vnode', formItem.itemTemplate, item)"></component>
                                <template v-else>{{ item[formItem.txtName] }}</template>
                                <template v-if="checkIsIncluding(item)"><i-icon style="flex-shrink: 0" color="#eb5353" name="iconcheckboxok" :size="15" /></template>
                                <template v-else><i-icon color="#13161B" style="flex-shrink: 0" name="iconcheckboxno" :size="15" /></template>
                            </div>
                        </template>
                        <div v-if="onPaginLock" style="display: flex; align-items: center; justify-content: center; height: 40px">
                            <div class="circle-loader" style="transform: scale(0.3)">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </i-popup>
        </template>
    </div>
</template>

<script>
import generic from "../../utils/generic";
import {getCookie} from "tiny-cookie";
export default {
    name: "Picker",
    props: {
        formTemplate: null,
        formData: null,
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    computed: {
        currentColumns() {
            return this.isSearch ? this.searchList : this.columns;
        },
    },
    data() {
        return {
            value: "",
            isSearch: false,
            columns: [],
            modelVal: "",
            showPicker: false,
            sourceData: "",
            defaultIndex: 0,
            searchList: [],
            selectedItems: [],
            onPaginLock: false,
            // 关键字
            keywords: "",
            // 分页数据
            currentIndex: 1,
            pageSize: this.formItem.pageSize,
            getCookie,
            isTMIS: getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'TMIS', // 辉瑞老yes
        };
    },
    watch: {
        // 出发一个输入提示
        selectedItems(newValue) {
            setTimeout(() => {
                if (newValue.length) {
                    this.$refs.inputHidden.value = newValue.map((x) => x[this.formItem.valName]);
                    if(this.isTMIS && this.formItem.controlName === 'PlaceAddress' && this.formItem.type === "multipleSelect"){
                        const  {code, name, originalCode, originalName} = newValue[0];
                        const target = {
                            code, name, originalCode,originalName
                        }
                        this.$smartStorage.set('PlaceAddress', target);
                    }
                } else {
                    this.$refs.inputHidden.value = "";
                }
                setTimeout(() => {
                    this.$refs.inputHidden.validate();
                }, 100);
            }, 10);
        },
        // 过滤当前选项
        keywords(newValue) {
            this.searchList = this.columns.filter((item) => {
                if (item[this.formItem.valName].includes(newValue) || item[this.formItem.txtName].includes(newValue)) {
                    return true;
                } else {
                    return false;
                }
            });
        },
    },
    created() {
        this.init();
        this.stashFormItem = this.jsonCopy(this.formItem);
    },
    methods: {
        scrollColumns(e) {
            if (this.formItem.postSearch) {
                let $0 = e.target;
                let isReachBottom = !($0.scrollHeight - $0.offsetHeight > $0.scrollTop);
                console.log("====到达底部", isReachBottom);
                if (isReachBottom) {
                    this.onPagin();
                }
            }
        },
        async reassign(OldData) {
            if (OldData != "") {
                console.log('OldData',OldData);
                this.pageSize = Math.ceil(OldData.split(",").length / 10) * 10;
                let optionsitems = await this.init();
                let newData = OldData.split(",").map((oldValue) => {
                    return optionsitems.find((x) => x[this.formItem.valName] == oldValue);
                });
                if (newData.every((x) => !!x)) {
                    this.selectedItems = newData;
                }
                let _this = this
                // 执行数据反显表达式，解决类似"搜索出来的会议协助者反显不显示的问题"
                if(this.formItem.dataEchoExper) {
                    try {
                        eval(this.formItem.dataEchoExper)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        },
        clearVal() {
            this.value = "";
        },
        checkIsIncluding(item) {
            return this.selectedItems.find((x) => x[this.formItem.valName] == item[this.formItem.valName]);
        },
        addSelectItem(item) {
            // 是否已经存在
            console.log("====选中一个项", item, this.selectedItems);
            if (this.selectedItems.find((x) => x[this.formItem.valName] == item[this.formItem.valName])) {
                // 已经存在
                this.deleteSelect(item);
            } else {
                // 添加
                if(this.formItem.selectOne){ // 单选，不要问为什么多选组件里要单选
                    this.selectedItems = [item];
                }else {
                    this.selectedItems.push(item);
                }

            }
        },
        deleteSelect(item) {
            this.selectedItems = this.selectedItems.filter((x) => x[this.formItem.valName] != item[this.formItem.valName]);
        },
        initShowPicker() {
            this.formItem.params = this.stashFormItem.params;
            this.showPicker = true;
            this.$refs.inputHidden.validate();
            this.init();
        },
        async init() {
            this.currentIndex = 1;
            this.isSearch = false;
            this.keywords = "";
            let resource;
            if (this.formItem.options?.length) {
                resource = this.formItem.options;
            } else {
                let action = this.formItem.method == "GET" ? "Get" : "common";
                if (this.formItem.expr) {
                    let context = this;
                    context;
                    try {
                        eval(this.formItem.expr);
                    } catch (error) {
                        console.log(error);
                    }
                }
                // 如果需要转换参数
                let params = this.formItem.params;
                if (this.formItem.replaceEvalPath && this.formItem.replaceEvalPath.length) {
                    params = generic.replaceEvalObject(this.formItem, this).params;
                }
                let res = await this.$service[action](this.formItem.url, params);
                console.log('res',res);
                if (res && res.success && res.content) {
                    // console.log("====多选框选择范围", res, this.formItem.controlName, this.formItem.txtName, this.formItem.valName);
                    if (this.formItem.dataContentPath) {
                        try {
                            resource = eval("res." + this.formItem.dataContentPath);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        resource = res.content.options;
                    }
                }else {
                    resource = res.rows || [];
                }
            }
            resource &&
                resource.map((item, index) => {
                    if (item[this.formItem.txtName] == this.value) {
                        this.defaultIndex = index;
                        item.defaultIndex = index;
                        this.sourceData = JSON.stringify(item);
                    }
                    if (item.children && item.children.length == 0) {
                        item.children = null;
                    }
                });

            this.columns = resource || [];
            return this.columns;
        },
        onConfirm(selectedItem, index) {
            //把显示到输入框的值显示为label而不是value
            this.value = selectedItem[this.formItem.txtName];
            this.showPicker = false;
            this.sourceData = JSON.stringify(selectedItem);
            this.$emit("callBack", selectedItem, index, this.formItem);
        },
        searchClear() {
            this.showPicker = false;
            this.keywords = "";
        },

        // 分页搜索然后加入现有columns

        async onPagin() {
            if (this.onPaginLock || this.isPageEnd) {
                return;
            } else {
                this.onPaginLock = true;
            }
            this.currentIndex += 1;
            this.keywords = this.keywords.trim();
            this.isSearch = false;
            let resource;
            if (this.formItem.options?.length) {
                resource = this.formItem.options;
            } else {
                let action = this.formItem.method == "GET" ? "Get" : "common";
                if (this.formItem.expr) {
                    let context = this;
                    context;
                    try {
                        eval(this.formItem.expr);
                    } catch (error) {
                        console.log(error);
                    }
                }
                // 如果需要转换参数
                let params = this.formItem.params;
                if (this.formItem.replaceEvalPath && this.formItem.replaceEvalPath.length) {
                    params = generic.replaceEvalObject(this.formItem, this).params;
                }
                let res = await this.$service[action](this.formItem.url, params);
                this.onPaginLock = false;
                if (res && res.success) {
                    console.log("====多选框选择范围", res, this.formItem.controlName, this.formItem.txtName, this.formItem.valName);
                    if (this.formItem.dataContentPath) {
                        try {
                            resource = eval("res." + this.formItem.dataContentPath);
                        } catch (error) {
                            console.log(error);
                        }
                    } else {
                        resource = res.content.options;
                    }
                }else {
                    resource = res.rows || [];
                }

                if (resource.length === 0) {
                    this.isPageEnd = true;
                }

                if (resource && resource.length) {
                    this.columns.push(...resource);
                }
            }
        },

        async onSearch() {
            // 是否使用接口搜索，搜索重置分页
            if (this.formItem.postSearch) {
                this.currentIndex = 1;
                this.isPageEnd = false;
                this.keywords = this.keywords.trim();
                this.isSearch = false;
                let resource;
                if (this.formItem.options?.length) {
                    resource = this.formItem.options;
                } else {
                    let action = this.formItem.method == "GET" ? "Get" : "common";
                    if (this.formItem.expr) {
                        let context = this;
                        context;
                        try {
                            eval(this.formItem.expr);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    // 如果需要转换参数
                    let params = this.formItem.params;
                    if (this.formItem.replaceEvalPath && this.formItem.replaceEvalPath.length) {
                        params = generic.replaceEvalObject(this.formItem, this).params;
                    }
                    let res = await this.$service[action](this.formItem.url, params);
                    if (res && res.success) {
                        console.log("====多选框选择范围", res, this.formItem.controlName, this.formItem.txtName, this.formItem.valName);
                        if (this.formItem.dataContentPath) {
                            try {
                                resource = eval("res." + this.formItem.dataContentPath);
                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            resource = res.content.options;
                        }
                    }else {
                        resource = res.rows || [];
                    }
                }

                this.columns = resource || [];
            } else {
                let val = this.keywords.trim();
                if (val) {
                    this.isSearch = true;
                } else {
                    this.isSearch = false;
                }
            }
        },
    },
};
</script>
<style>
.flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flexbox > div {
    width: 300px;
    height: 300px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}

.circle-loader {
    position: relative;
    width: auto;
    height: auto;
}

.circle-loader div {
    height: 10px;
    width: 10px;
    background-color: #f44336;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: 0.8s opaque ease-in-out infinite both;
    animation: 0.8s opaque ease-in-out infinite both;
}

.circle-loader > div:nth-child(1) {
    top: -25px;
    left: 0;
}
.circle-loader > div:nth-child(2) {
    top: -17px;
    left: 17px;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.circle-loader > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.circle-loader > div:nth-child(4) {
    top: 17px;
    left: 17px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}
.circle-loader > div:nth-child(5) {
    top: 25px;
    left: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.circle-loader > div:nth-child(6) {
    top: 17px;
    left: -17px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.circle-loader > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.circle-loader > div:nth-child(8) {
    top: -17px;
    left: -17px;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

@-webkit-keyframes opaque {
    0% {
        opacity: 0.1;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes opaque {
    0% {
        opacity: 0.1;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.1;
    }
}
</style>
<style lang="less">
#multipleHiddenInput {
    .van-field__body {
        display: none;
    }
    div.van-field {
        margin-bottom: 0;
        border: 0;
    }
}
</style>
<style lang="less" scoped>
.el-tag.el-tag--info {
    color: #909399;
    box-sizing: border-box;
    border-color: transparent;
    margin: 2px 6px 2px 0;
    background-color: #f0f2f5;
    display: flex;
    width: fit-content;
    align-items: center;
    text-align: left;
    padding: 0 8px;
    line-height: 22px;
    border-radius: 4px;
    svg {
        margin-left: 5px;
    }
}

.tb_select {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
}
.tb_select_bg {
    width: 100%;
    height: 100%;
}
.tb_select_box {
    background: #ffffff;
    z-index: 10;
    position: relative;
    height: 100%;
}
.select_top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 38px;
    z-index: 10;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select_confirm,
.select_cancel {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 14px;
}
.select_cancel {
    color: #999999;
}
.select_title {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    font-size: 14px;
}
.select_end {
    width: 100%;
    height: 100%;
    max-height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 38px 10px 0;
    box-sizing: border-box;
}
.select_item {
    width: 100%;
    height: 38px;
    font-size: 14px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
