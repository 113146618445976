var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-field", {
        attrs: {
          readonly: "",
          clickable: "",
          "validate-trigger": "onChange",
          name: _vm.formItem.controlName,
          disabled: _vm.isDisable,
          value: _vm.value,
          placeholder: _vm.formItem.placeholder,
          rules: _vm.rules,
        },
        on: { click: _vm.initPicker },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("div", [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.formItem.label)),
                  ]),
                  _vm.formItem.rules && _vm.formItem.rules[0].required
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ])
                    : _vm._e(),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.formItem.tips) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "i-popup",
        {
          attrs: { position: "bottom", "get-container": "#app" },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _vm.formItem.componentType === "time"
            ? _c("DatetimePicker", {
                attrs: {
                  type: "time",
                  title: "选择时间",
                  "min-hour": 0,
                  "max-hour": 23,
                },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function ($event) {
                    _vm.showPicker = false
                  },
                },
                model: {
                  value: _vm.currentDate,
                  callback: function ($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate",
                },
              })
            : _c("i-datetime-picker", {
                attrs: {
                  type: _vm.formItem.componentType
                    ? _vm.formItem.componentType
                    : "datetime",
                  title: _vm.formItem.label,
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                  filter: _vm.filter,
                },
                on: {
                  confirm: _vm.onConfirm,
                  cancel: function ($event) {
                    _vm.showPicker = false
                  },
                },
                model: {
                  value: _vm.currentDate,
                  callback: function ($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }