var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-field", {
        attrs: {
          readonly: "",
          clickable: "",
          name: _vm.formItem.controlName,
          disabled: _vm.isDisable,
          value: _vm.value,
          placeholder: _vm.formItem.placeholder,
          rules: _vm.formItem.rules,
        },
        on: { click: _vm.initShowPicker },
        scopedSlots: _vm._u(
          [
            {
              key: "label",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.formItem.label)),
                    ]),
                    _vm.formItem.rules && _vm.formItem.rules[0].required
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.formItem.tips) },
                  }),
                ]
              },
              proxy: true,
            },
            !_vm.formItem.rules
              ? {
                  key: "right-icon",
                  fn: function () {
                    return [
                      _c("vIcon", {
                        attrs: { name: "close" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clearVal($event)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "i-popup",
        {
          ref: "popupInst",
          attrs: {
            id: _vm.currentDomId,
            position: "bottom",
            "get-container": "#app",
          },
          on: {
            close: function ($event) {
              return _vm.searchClear()
            },
          },
          model: {
            value: _vm.showPicker,
            callback: function ($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker",
          },
        },
        [
          _vm.formItem.showSearch
            ? _c("i-search", {
                attrs: { "show-action": "", placeholder: "请输入搜索关键词" },
                on: {
                  clear: function ($event) {
                    return _vm.searchClear()
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "action",
                      fn: function () {
                        return [
                          _c("div", { on: { click: _vm.onSearch } }, [
                            _vm._v("搜索"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3446203101
                ),
                model: {
                  value: _vm.keywords,
                  callback: function ($$v) {
                    _vm.keywords = $$v
                  },
                  expression: "keywords",
                },
              })
            : _vm._e(),
          _c("i-picker", {
            ref: "ipickerinst",
            attrs: {
              "swipe-duration": "300",
              "value-key": _vm.formItem.txtName,
              "show-toolbar": "",
              "default-index": _vm.defaultIndex,
              columns: _vm.isSearch ? _vm.searchList : _vm.columns,
            },
            on: {
              confirm: _vm.onConfirm,
              cancel: function ($event) {
                _vm.showPicker = false
              },
            },
          }),
        ],
        1
      ),
      _c("i-field", {
        staticStyle: { display: "none" },
        attrs: { readonly: "", name: _vm.formItem.controlName + "SourceData" },
        model: {
          value: _vm.sourceData,
          callback: function ($$v) {
            _vm.sourceData = $$v
          },
          expression: "sourceData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }