<template>
    <div style="position: relative" :comName="formItem.type">
        <div class="readOnlyCover" v-if="isDisable" v-on:click.stop="preventEvent"></div>
        <!-- 选择输入后有回调 -->
        <Cascader :formData="formData" v-if="formItem.type == 'iCascaderRest' && !['eventCity', 'EventCity'].includes(formItem.controlName)"
                  :is-disable="isDisable" :class="isDisable ? 'disableBox' : ''"
                  :form-item="formItem" :modelVal="modelVal" @callBack="callBack"></Cascader>
        <CascaderCity :formData="formData" v-else-if="['eventCity', 'EventCity'].includes(formItem.controlName)" :form-item="formItem"
                      :is-disable="isDisable" :class="isDisable ? 'disableBox' : ''"
                      :modelVal="modelVal" @callBack="callBack"></CascaderCity>
        <Picker :formData="formData" v-else-if="formItem.type == 'iSelectRest'" :formTemplate="formTemplate"
                :is-disable="isDisable" :class="isDisable ? 'disableBox' : ''"
                :form-item.sync="formItem" :modelVal="modelVal" @callBack="callBack"></Picker>
        <multiple-select :formData="formData" v-else-if="formItem.type == 'multipleSelect'" :formTemplate="formTemplate"
                         :disabled="isDisable" :class="isDisable ? 'disableBox' : ''"
                         :form-item="formItem" :modelVal="modelVal" @callBack="callBack"></multiple-select>
        <automatic-fill :formData="formData" v-else-if="formItem.type == 'automaticFill'" @callBack="callBack"
                        :disabled="isDisable" :class="isDisable ? 'disableBox' : ''"
                        :form-item.sync="formItem" :modelVal="modelVal"></automatic-fill>
        <i-field v-else-if="formItem.type == 'hidden'" v-show="false" :name="formItem.controlName"
                 v-model="modelVal"></i-field>
        <DateTimePicker v-else-if="formItem.type == 'iDatePicker'" :formData="formData" :form-item="formItem"
                        :disabled="isDisable" :class="isDisable ? 'disableBox' : ''"
                        :modelVal="modelVal" @callBack="callBack"></DateTimePicker>
        <!-- 直接输入无回调 -->
        <i-field v-else-if="formItem.type != 'iSubTitle' && formItem.type != 'iSelectHotel'"
                 :class="isDisable ? 'disableBox' : ''"
                 :readonly="isDisable" :disabled="isDisable" :type="inputType" :rows="inputType == 'textarea' ? 4 : 1" autosize
                 v-model="modelVal" @input="inputChange" :name="formItem.controlName" :maxlength="maxlength"
                 :formatter="intArrType.includes(formItem.controlName) ? IntFormatter : formatter"
                 :placeholder="formItem.placeholder" :rules="formItem.rules" show-word-limit @focus="handleFocus(formItem)">
            <template #label>
                <div>
                    <span class="title">{{ formItem.label }}</span>
                    <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                </div>
                <!-- 静态提示-->
                <div v-html="formItem.tips"></div>
            </template>
        </i-field>
    </div>
</template>

<script>
import Cascader from "./Cascader";
import Picker from "./Picker";
import DateTimePicker from "./DateTimePicker";
import CascaderCity from "./CascaderCity";
import MultipleSelect from "./MultipleSelect.vue";
import AutomaticFill from "./automaticFill.vue";
import validatorMap from "@/utils/validators.js";

export default {
    name: "iFormItem",
    components: {
        Cascader,
        Picker,
        DateTimePicker,
        CascaderCity,
        MultipleSelect,
        AutomaticFill,
    },
    props: {
        formTemplate: null,
        formData: null,
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [String, Number, Array, Object],
    },
    computed: {
        isDisable() {
            if (this.formItem.readonly) {
                return true;
            }
            if(this.formItem.disabled) return this.formItem.disabled
            if (this.formItem.readonlyExpr) {
                let miceInfo = this.$smartStorage.get("miceInfo") || {};
                let context = {
                    miceInfo,
                    formData: this.formData,
                    path: this.$route.path,
                    speakerInfo: this.$smartStorage.get('genericDetailInfo') || {}
                };
                try {
                    return eval(this.formItem.readonlyExpr);
                } catch (error) {
                    console.log(error);
                }
            }

            return false;
        },
        maxlength() {
            let len = this.formItem.maxSize || null;
            switch (this.formItem.controlName) {
                case "meetingName":
                    len = 200;
                    break;
                case "remarks":
                    len = 500;
                    break;
                default:
                    break;
            }
            return len;
        },
        inputType() {
            switch (this.formItem.type) {
                case "iNumberInput":
                case "number":
                    return "number";
                case "iTextarea":
                    return "textarea";
                default:
                    return "text";
            }
        },
    },

    data() {
        return {
            // 输入需限制为正整数的字段
            intArrType: ["participantsNumber"],
        };
    },
    created() {
        if (this.formItem.validator) {
            this.formItem.rules.push({
                validator: validatorMap[this.formItem.validator].func,
                message: validatorMap[this.formItem.validator].msg,
            });
        }
        if (this.formItem.defaultValue !== undefined && this.$route.query.type != "edit") {
            this.formData[this.formItem.controlName] = this.formItem.defaultValue
        }
        if (this.formItem.type == 'hidden' && this.formItem.expr && eval(this.formItem.expr)) {
            let evalStr = this.jsonCopy(this.formItem.hiddenValue || "")
            this.formData[this.formItem.controlName] = this.replaceVal(evalStr);
        }
    },
    methods: {
        handleFocus(item){
            if(item.onFocus) eval(item.onFocus);
        },
        replaceVal(evalStr) {
            if (evalStr.indexOf('context') == -1) return evalStr
            let context = this.$smartStorage.get('miceInfo') || {}
            let val = ''
            try {
                val = eval(evalStr)
            } catch (error) {
                console.log(`${evalStr}_error`, error)
            }
            return val
        },
        preventEvent() {
        },
        inputChange(e) {
            this.$emit("callBack", e, 0, this.formItem);
        },
        formatter(value) {
            if (this.formItem.formatterExpr) {
                let _RegExp = new RegExp(this.formItem.formatterExpr, "g");
                return value.replace(_RegExp, '')
            }
            if (this.formItem.type == "iNumberInput") {
                return value.trim().replace(/-/g, "");
            } else {
                return value.trim();
            }
        },
        // 正整数过滤
        IntFormatter(value) {
            return value.replace(/^(0+)|[^\d]+/g, "");
        },
        callBack(value, index, formItem) {
            this.$emit("callBack", value, index, formItem);
        },
    },
};
</script>

<style lang="less" scoped>
.readOnlyCover {
    //background-color: #FFFFFF;
    //opacity: 0.4;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
}

/deep/ .van-field {
    flex-direction: column;
    border-bottom: 1px solid #f5f5f5;
    padding: unset;
    margin-bottom: 0.15rem;

    .van-field__label {
        width: unset;
        margin: unset;
        font-size: 0.14rem;
        font-weight: 500;
        color: #13161b;
        .title {
            font-size: 0.15rem;
        }
    }

    .van-field__value {
        font-size: 0.14rem;
        height: 0.35rem;
        line-height: 0.35rem;
        font-weight: 500;
    }
    .van-field__control {
        font-size: 0.15rem !important;
    }
    .van-field__control::placeholder {
        font-size: 0.15rem !important;
        color: #909399;
        -webkit-text-fill-color: #909399 !important;
    }
}

/deep/ .van-field--error {
    .van-field__control::placeholder {
        color: #909399;
        -webkit-text-fill-color:#909399 !important;
    }
}

/deep/ .disableBox {
    .van-field__label {
        color: #c0c4cc !important;
        .title {
            color: #c0c4cc !important;
        }
    }
    .van-field__control {
        color: #c0c4cc !important;
    }
    .van-field__control::placeholder {
        color: #c0c4cc;
        -webkit-text-fill-color:#c0c4cc !important;
    }
}

/deep/ .van-field__body {
    textarea {
        background: #F5F5F5;
        border-radius: 0.04rem;
        padding: 0 0.1rem;
        box-sizing: border-box;
    }
}
</style>
