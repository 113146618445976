var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("i-field", {
        staticClass: "formField",
        attrs: {
          clickable: "",
          readonly: "",
          name: _vm.formItem.controlName,
          disabled: _vm.isDisable,
          placeholder: _vm.formItem.placeholder,
          rules: _vm.formItem.rules,
        },
        on: {
          click: function () {
            if (!_vm.isDisable) {
              _vm.show = true
            }
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("div", [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.formItem.label)),
                  ]),
                  _vm.formItem.rules && _vm.formItem.rules[0].required
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ])
                    : _vm._e(),
                ]),
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.formItem.tips) },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.fieldValue,
          callback: function ($$v) {
            _vm.fieldValue = $$v
          },
          expression: "fieldValue",
        },
      }),
      _c(
        "i-popup",
        {
          attrs: { position: "bottom", "get-container": "#app" },
          on: { "click-overlay": _vm.onFinish },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("i-cascader", {
            attrs: {
              "active-color": "var(--themeColor)",
              title: _vm.formItem.label,
              options: _vm.options,
              "field-names": _vm.fieldNames,
            },
            on: {
              close: function ($event) {
                _vm.show = false
              },
              finish: _vm.onFinish,
              change: _vm.onCityChange,
            },
            model: {
              value: _vm.cascader,
              callback: function ($$v) {
                _vm.cascader = $$v
              },
              expression: "cascader",
            },
          }),
        ],
        1
      ),
      _c("i-field", {
        staticStyle: { display: "none" },
        attrs: { readonly: "", name: _vm.formItem.controlName + "SourceData" },
        model: {
          value: _vm.sourceData,
          callback: function ($$v) {
            _vm.sourceData = $$v
          },
          expression: "sourceData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }