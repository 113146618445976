<template>
    <div>
        <i-field
            class="formField"
            v-model="fieldValue"
            clickable
            readonly
            :name="formItem.controlName"
            :disabled="isDisable"
            :placeholder="formItem.placeholder"
            :rules="formItem.rules"
            @click="
                () => {
                    if (!isDisable) show = true;
                }
            "
        >
            <template #label>
                <div>
                    <span class="title">{{ formItem.label }}</span>
                    <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                </div>
                <div v-html="formItem.tips"></div>
            </template>
        </i-field>
        <i-popup v-model="show" @click-overlay="onFinish" position="bottom" get-container="#app">
            <i-cascader active-color="var(--themeColor)" v-model="cascader" :title="formItem.label" :options="options" :field-names="fieldNames" @close="show = false" @finish="onFinish" @change="onCityChange" />
        </i-popup>
        <!--存储数据源-->
        <i-field style="display: none" v-model="sourceData" readonly :name="formItem.controlName + 'SourceData'" />
    </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    name: "CascaderCity",
    props: {
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [Array, String],
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            fieldValue: "",
            cascader: "",
            options: [],
            show: false,
            sourceData: "",
            newModelVal: [],
            selectedOptions: [],
            fieldNames: {
                text: this.formItem.txtName || "txt",
                value: this.formItem.valName || "val",
                children: this.formItem.children || "children",
            },
            children: this.formItem.children || "children",
            valName: this.formItem.valName || "val",
            txtName: this.formItem.txtName || "txt",
        };
    },
    created() {
        this.init();
        if (this.modelVal) {
            this.newModelVal = this.$publicFun.getObjType(this.modelVal) != "Array" ? JSON.parse(this.modelVal) : this.modelVal;
        }
    },
    methods: {
        ...mapMutations(["setValue"]),
        reassign(fieldValue, cascader) {
            this.fieldValue = fieldValue;
            this.cascader = cascader;
        },
        async init() {
            let action = this.formItem.method == "GET" ? "Get" : "common";
            let res = await this.$service[action](this.formItem.url, this.formItem.params);
            if (res && res.success) {
                if (this.newModelVal.length > 0) {
                    this.fieldValue = this.newModelVal.map((option) => option).join("/");
                    let index = this.newModelVal.length - 1;
                    if (this.newModelVal[index]) this.cascader = Number(this.newModelVal[index]);
                    this.sourceData = JSON.stringify(this.newModelVal);
                }
                if (this.formItem.provinceFilter) { // 筛选条件1：筛选出特定的省份
                    let filterArr = this.formItem.provinceFilter.split(",");
                    res.content = res.content.filter((item) => {
                        return filterArr.includes(item.valId);
                    });
                }
                if(this.formItem.mainlandProvince){ // 筛选条件2：只选大陆的省份
                    res.content = res.content.filter((item) => {
                        return !['810000', '710000', '820000'].includes(item.valId);
                    });
                }
                if(this.formItem.isNeedAssemble){ // 这一块主要针对辉瑞
                    console.log('组装内--',this.assembleCascader(res.content));
                    res.content = this.assembleCascader(res.content);
                }
                this.options = res.content;
            }
        },
        assembleCascader(arr) {
            let obj = {};
            arr.forEach(i=>{
                obj[i.lnkVal
                    ] = arr.filter(x => i.lnkVal === x.lnkVal);
            })
            return Object.entries(obj).map(n => ({
                txt: n[0],
                val: n[0],
                children: n[1]
            })).filter(m => !['null'].includes(m.txt))
        },
        onCityChange({ selectedOptions }) {
            // console.log('onCityChange',selectedOptions);
            this.selectedOptions = selectedOptions;
            this.$smartStorage.set('cityData', selectedOptions[1].itsExtData.pingCityId);
            
        },
        onFinish() {
            if (this.selectedOptions.length < 2) {
                this.$itoast("请至少选择到市级城市！");
                return;
            }
            this.show = false;
            this.fieldValue = this.selectedOptions.map((option) => option[this.txtName]).join("/");
            let tmp = [];
            let selectedArr = [];
            selectedArr = this.selectedOptions;
            selectedArr.forEach((item) => {
                let tmpObj = {};
                for (const itemKey in item) {
                    if ([this.txtName, this.valName].includes(itemKey)) {
                        tmpObj.txt = item[this.txtName];
                        tmpObj.val = item[this.valName];
                    }
                }
                tmp.push(tmpObj);
            });
            this.sourceData = JSON.stringify(tmp);

            let callBackValue = "";
            if(this.formItem.isJointName){ // 表示城市的结果需要用斜杠拼/接成拼接成省市区
                callBackValue = this.selectedOptions.map(i => i[this.txtName]).join('/');
            }else {
                callBackValue = this.selectedOptions[this.selectedOptions.length - 1][this.txtName]
            }
            this.$emit("callBack", callBackValue, this.selectedOptions, this.formItem);
        },
        toClearable() {
            this.fieldValue = "";
            this.sourceData = "";
            this.defaultIndex = 0;
        },
    },
};
</script>

<style lang="less" scoped>
//.title {
//    font-size: 0.14rem;
//    font-weight: 500;
//    color: #828282;
//}
</style>
