<template>
    <div>
        <i-field v-model="fieldValue" clickable readonly :disabled="isDisable" :name="formItem.controlName" :placeholder="formItem.placeholder" :rules="formItem.rules" @click="show = true">
            <template #left-icon v-if="formItem.showLeftIcon">
                <i-icon name="icon-address" color="var(--themeColor)"></i-icon>
            </template>
            <template #label>
                <div>
                    <span class="title">{{ formItem.label }}</span>
                    <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                </div>
                <div v-html="formItem.tips"></div>
            </template>
        </i-field>
        <i-popup v-model="show" position="bottom">
            <i-cascader v-model="cascaderValue" :title="formItem.label" :options="options" :field-names="fieldNames[formItem.controlName]" @close="show = false" @finish="onFinish" />
        </i-popup>
        <!--存储数据源-->
        <i-field style="display: none" v-model="sourceData" readonly :name="formItem.controlName + 'SourceData'" />
    </div>
</template>

<script>
export default {
    name: "Cascader",
    props: {
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [Array, String],
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            show: false,
            fieldValue: "",
            sourceData: "",
            cascaderValue: "",
            options: [],
            fieldNames: {},
        };
    },
    created() {
        this.setFiledName();
        this.init();
    },
    methods: {
        async init() {
            let action = this.formItem.method == "GET" ? "Get" : "common";
            let res = await this.$service[action](this.formItem.url, this.formItem.params);
            if (res && res.success) {
                res.content.map((item) => {
                    if (item.children && item.children.length == 0) {
                        item.children = null;
                    } else {
                        item.children.map((val) => {
                            if (val.children && val.children.length == 0) {
                                val.children = null;
                            }
                        });
                    }
                });

                console.log('////',this.modelVal);
                if (this.modelVal != "") {
                    this.fieldValue = this.formItem.controlName == "eventCity" ? this.modelVal.join("/") : this.modelVal.map((option) => option[this.fieldNames[this.formItem.controlName].text]).join("/");
                    let index = this.modelVal.length - 1;
                    this.cascaderValue = this.formItem.controlName == "eventCity" ? this.modelVal[index] : this.modelVal[index][this.fieldNames[this.formItem.controlName].value];
                }
                if (this.formItem.provinceFilter) {
                    let filterArr = this.formItem.provinceFilter.split(",");
                    res.content = res.content.filter((item) => {
                        return filterArr.includes(item.valId);
                    });
                }
                this.options = res.content;
            }
        },
        onFinish({ value, selectedOptions }) {
            console.log(selectedOptions);
            this.show = false;
            this.fieldValue = selectedOptions.map((option) => option[this.fieldNames[this.formItem.controlName].text]).join("/");
            let tmp = [];
            selectedOptions.forEach((item) => {
                let tmpObj = {};
                for (const itemKey in item) {
                    if (itemKey != "children") {
                        tmpObj[itemKey] = item[itemKey];
                    }
                }
                tmp.push(tmpObj);
            });
            this.sourceData = JSON.stringify(tmp);
            this.$emit("callBack", value, selectedOptions, this.formItem);
        },
        setFiledName() {
            this.fieldNames[this.formItem.controlName] = {
                text: this.formItem.txtName || "txt",
                value: this.formItem.valName || "val",
                children: this.formItem.children || "children",
            };
        },
    },
};
</script>

<style scoped></style>
