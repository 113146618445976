<template>
    <div>
        <div>
            <i-field ref="inputHidden" style="" :type="inputType" :disabled="isDisable" rows="1" :readonly="true" autosize v-model="modelVal" @click.stop="initShowPicker" :name="formItem.controlName" :maxlength="maxlength" :placeholder="formItem.placeholder" :rules="formItem.rules" show-word-limit>
                <template #label>
                    <div>
                        <span class="title">{{ formItem.label }}</span>
                        <span v-if="formItem.rules && formItem.rules[0].required" style="color: red">*</span>
                    </div>
                    <div v-html="formItem.tips"></div>
                </template>
            </i-field>
        </div>
        <i-popup v-model="showPicker" position="bottom" get-container="#app" style="height: 100%">
            <i-search v-model="keywords" v-if="formItem.showSearch" @clear="onSearch" show-action placeholder="请输入搜索关键词">
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </i-search>
            <div class="tb_select_box" :style="{ height: `calc(100% - ${formItem.showSearch ? '54px' : 0})` }">
                <div class="select_top">
                    <div class="select_cancel picker_select_btn" @click="searchClear()" type="cancel">取消</div>
                    <div class="select_title">请选择</div>
                    <div class="select_confirm picker_select_btn" @click="automaticFillValue" type="confirm">确定</div>
                </div>
                <div class="select_end">
                    <template v-for="(item, index) in currentColumns">
                        <div :key="index" @click="currentItem = item" class="select_item picker_select_btn">
                            <div>
                                <template v-for="(label, lindex) in formItem.itemLabels">
                                    <div :key="lindex" v-if="$evalTemplate(label.render, item)">
                                        {{ label.label }}：
                                        {{ $evalTemplate(label.render, item) }}
                                    </div>
                                </template>
                            </div>
                            <template v-if="currentItem == item"><i-icon color="#eb5353" name="iconcheckboxok" :size="15" /></template>
                            <template v-else><i-icon color="#13161B" name="iconcheckboxno" :size="15" /></template>
                        </div>
                    </template>
                </div>
            </div>
        </i-popup>
    </div>
</template>

<script>
import generic from "../../utils/generic";
export default {
    name: "Picker",
    props: {
        formTemplate: null,
        formItem: {
            type: Object,
            default() {
                return {};
            },
        },
        modelVal: [String, Number, Object],
        isDisable: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    computed: {
        currentColumns() {
            return this.isSearch ? this.searchList : this.columns;
        },
    },
    data() {
        return {
            value: "",
            isSearch: false,
            columns: [],

            showPicker: false,
            sourceData: "",
            defaultIndex: 0,
            keywords: "",
            searchList: [],
            selectedItems: [],
            currentItem: {},
        };
    },
    watch: {
        // 过滤当前选项
        keywords(newValue) {
            this.searchList = this.columns.filter((item) => {
                return JSON.stringify(item).includes(newValue);
            });
        },
    },
    created() {
        this.stashFormItem = this.jsonCopy(this.formItem);
    },
    methods: {
        automaticFillValue() {
            this.showPicker = false;
            this.$emit("callBack", this.currentItem, 0, this.formItem);
        },
        async reassign(OldData) {
            let items = await this.init();

            let newData = OldData.split(",").map((oldValue) => {
                // 从值域中选中已有项
                return items.find((x) => x[this.formItem.valName] == oldValue);
            });
            this.selectedItems = newData;
        },
        clearVal() {
            this.value = "";
        },
        checkIsIncluding(item) {
            return this.selectedItems.find((x) => x[this.formItem.valName] == item[this.formItem.valName]);
        },
        addSelectItem(item) {
            // 是否已经存在
            console.log("====选中一个项", item, this.selectedItems);
            if (this.selectedItems.find((x) => x[this.formItem.valName] == item[this.formItem.valName])) {
                // 已经存在
                this.deleteSelect(item);
            } else {
                // 添加
                this.selectedItems.push(item);
            }
        },
        deleteSelect(item) {
            this.selectedItems = this.selectedItems.filter((x) => x[this.formItem.valName] != item[this.formItem.valName]);
        },
        initShowPicker() {
            this.formItem.params = this.stashFormItem.params;
            if (this.formItem.isDepend) {
                if (this.formTemplate[0].find((x) => x.controlName == this.formItem.dependControlName)) {
                    if (this.formTemplate[0].find((x) => x.controlName == this.formItem.dependControlName).selectedValue) {
                        let dependency = this.formTemplate[0].find((x) => x.controlName == this.formItem.dependControlName).selectedValue[this.formItem.dependParamValue];
                        this.formItem.params[this.formItem.dependParamName] = dependency;
                    }
                }
            }
            this.showPicker = true;
            this.init();
        },
        async init() {
            //  "txtName": "txt",
            //   "valName": "val",
            this.isSearch = false;
            this.keywords = "";
            let resource;
            if (this.formItem.options?.length) {
                resource = this.formItem.options;
            } else {
                // 如果需要替换数据
                let newFormItem = this.formItem;
                if (this.formItem.replaceEvalPath) {
                    newFormItem = generic.replaceEvalObject(newFormItem, this.$smartStorage.get("userMsg"));
                }
                let action = newFormItem.method == "GET" ? "Get" : "common";
                let res = await this.$service[action](newFormItem.url, newFormItem.params);
                if (res && res.success) {
                    let options = res[newFormItem.dataContentPath];
                    console.log("====自动填充选项", options);
                    resource = options;
                }
            }
            resource.map((item, index) => {
                if (item[this.formItem.txtName] == this.value) {
                    this.defaultIndex = index;
                    item.defaultIndex = index;
                    this.sourceData = JSON.stringify(item);
                }
                if (item.children && item.children.length == 0) {
                    item.children = null;
                }
            });

            this.columns = resource;

            if (this.modelVal) {
                this.currentItem = this.columns.find((x) => x[this.formItem.controlName] == this.modelVal);
            }

            return this.columns;
        },
        onConfirm(selectedItem, index) {
            this.formTemplate[0].find((x) => x.controlName == this.formItem.controlName).selectedValue = selectedItem;
            //把显示到输入框的值显示为label而不是value
            this.value = selectedItem[this.formItem.txtName];
            this.showPicker = false;
            this.sourceData = JSON.stringify(selectedItem);
            this.$emit("callBack", selectedItem, index, this.formItem);
        },
        searchClear() {
            this.showPicker = false;
            this.keywords = "";
            this.selectedItems = [];
        },
        onSearch() {
            let val = this.keywords.trim();
            if (val) {
                this.isSearch = true;
            } else {
                this.isSearch = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-tag.el-tag--info {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
    box-sizing: border-box;
    border-color: transparent;
    margin: 2px 0 2px 6px;
    background-color: #f0f2f5;
    display: flex;
    max-width: 100%;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    line-height: 22px;
    border-radius: 4px;
    svg {
        margin-left: 5px;
    }
}

.tb_select {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
}
.tb_select_bg {
    width: 100%;
    height: 100%;
}
.tb_select_box {
    background: #ffffff;
    z-index: 10;
    position: relative;
    height: 100%;
}
.select_top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 38px;
    z-index: 10;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select_confirm,
.select_cancel {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 14px;
}
.select_cancel {
    color: #999999;
}
.select_title {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    font-size: 14px;
}
.select_end {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 38px 10px 0;
    box-sizing: border-box;
}
.select_item {
    width: 100%;
    font-size: 14px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    padding-top: 10px;
}
</style>
